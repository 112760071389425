import React from "react";
import SocialShare from "../SocialShare/SocialShare";
import './assets/styles/_index.scss';

const CareerDetailInfo = (props) => {
    return (
        <ul className="list-inline career-detail-info-list d-flex align-items-center">
            {props.location && <li className="list-inline-item">{props.location}</li> }
            {props.salary && <li className="list-inline-item">{props.salary}</li> }
            <li className="list-inline-item">
                <SocialShare
                    iconClass="icon icon-share"
                    shareText="Share"
                    shareClass="property-contact news-detail"
                />
            </li>
        </ul>
    )
}

export default CareerDetailInfo