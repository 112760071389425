import React from "react";
import SocialShare from "../SocialShare/SocialShare";
import './assets/styles/_index.scss';

const NewsDetailInfo = ({ date, author, category }) => {

    function capitalizeWords(arr) {
        return arr.map((word) => {
            const capitalizedFirst = word.charAt(0).toUpperCase();
            const rest = word.slice(1).toLowerCase();
            return capitalizedFirst + rest;
        });
    }

    var cat_list = ''
    if (category) {
        cat_list = capitalizeWords(category)
    }

    return (
        <ul className="list-inline news-detail-info-list d-flex align-items-center">
            {date && <li className="list-inline-item">{date}</li>}
            {category && category.length > 0 && <li className="list-inline-item">{String(cat_list).replace(",", ", ")}</li>}
            {author?.name && <li className="list-inline-item">by <span>{author?.name}</span></li>}
            <li className="list-inline-item">
                <SocialShare
                    iconClass="icon icon-share"
                    shareText="Share"
                    shareClass="property-contact news-detail"
                />
            </li>
        </ul>
    )
}

export default NewsDetailInfo