import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import NewsDetailInfo from "../NewsDetailInfo/NewsDetailInfo";
import CareerDetailInfo from "../CareerDetailInfo/CareerDetailInfo";
import './styles/_index.scss'
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const StaticIntro = (props) => {
    return (
        <div className={`static-intro-wrapper ${props.staticClass}`}>
            <Container className={props.noborderTag === "no-border-tag" ? "p-0" : ""}>
                <Row>
                    <Col xl={props.tag === "inner-large" ? 12 : 7}>
                        <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={50}><h1 className='atlas-heading-h3'>{props.title ? props.title : props.pagename}</h1></ScrollAnimation>
                        {props.content &&
                            <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={300} offset={50}><p className='atlas-para'><ContentModule Content={props.content?.data?.content} /></p></ScrollAnimation>}
                        {
                            props.tag === "news-detail" ?
                                <NewsDetailInfo
                                    date={props.date}
                                    author={props?.author}
                                    category={props?.category?.strapi_json_value}
                                />
                                :
                                props.tag === "career-detail" ?
                                    <CareerDetailInfo {...props} />
                                    :
                                    ""
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StaticIntro