import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import loadable from "@loadable/component";

import { PageLinks } from "../../../common/site/page-static-links";
import CareerFormFields from "../../../forms_config/career_form.json";

import LayoutTwo from "../../../components/layoutTwo";
import Seo from "../../../components/seo"
import StaticIntro from "../../../components/StaticIntro/StaticIntro";


const BreadcrumbModule = loadable(() => import("../../../components/BreadcrumbModule/BreadcrumbModule"));
const ValuationContact = loadable(() => import("../../../components/ValuationContact/ValuationContact"));
const JobApplyForm = loadable(() => import("../../../components/forms/default-form-layout"));

const JobApply = (props) => {

    let breadcrumData;

    if (PageLinks?.career_parent_label) {
        breadcrumData = { parentlabel: PageLinks.career_parent_label, parentlink: PageLinks.career_parent, subparentlabel: PageLinks.career_label, subparentlink: PageLinks.career, title: 'Apply for this Job' }
    } else {
        breadcrumData = { parentlabel: PageLinks.career_label, parentlink: PageLinks.career, title: 'Apply for this Job' }
    }

    return (
        <LayoutTwo>
            <div className="layout-padding-top">
                <BreadcrumbModule {...breadcrumData} />
                <StaticIntro title={'Apply for this Job'} />
                <Container>
                    <Row>
                        <Col xl={7}>
                            <div className="inner-form-wrapper">
                                <JobApplyForm fields={CareerFormFields} classname="enquiry-form-wrapper" />
                            </div>
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                            <ValuationContact tag="inner-form" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </LayoutTwo>
    )
}

export const Head = () => <Seo title="Apply for this Job" />

export default JobApply